<template>
  <div class="wr print-page-component">
    <v-dialog v-model="show_email_dialog" width="600" persistent class="non-printable">
      <v-card>
        <v-card-text class="pt-4">
          <v-alert type="success" outlined rounded>
            Вкажіть email отримувача. По замовчуванні підставляється email адреса власника помешкання
          </v-alert>
          <v-form v-model="formValid" ref="form">
            <v-text-field
                v-model="email"
                label="Email"
                hide-details
                filled
                required
                color="grey"
                :rules="emailRules"
                :class="email ? '' : 'req-start'"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="email_dialog_send"
                 :loading="generate_email"
                 class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Надіслати
          </v-btn>
          <v-btn depressed text tile color="grey" @click="email_dialog_close">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="hidden-size-a4-portrait" class="non-printable" style="height: 286mm; visibility: hidden; z-index: -99999; position: absolute"/>
    <div id="hidden-size-a4-landscape" class="non-printable" style="height: 209.8mm; visibility: hidden; z-index: -99999; position: absolute;"/>
    <v-app-bar dense color="grey lighten-4" elevation="1"
               :height="appBarHeight"
               class="non-printable"
               style="position: relative;
               z-index: 1;">
      <div class="notify" v-if="!report_data_loaded && $vuetify.breakpoint.mdAndUp">
        <div class="icon">
          <v-icon size="34" color="success" class="mr-4">mdi-bell-alert-outline</v-icon>
        </div>
        <div>Звіт не сформований. Натисніть сформувати</div>
      </div>
      <template v-if="$vuetify.breakpoint.sm">
        <div class="py-2" style="width: 100%;">
          <div class="d-flex">
            <v-btn
                class="grey lighten-2 mr-2"
                @click.stop="generate_report"
                :disabled="!date_start || !date_end"
                :loading="report_loading"
            >
              <v-icon left class="mr-2" color="primary">mdi-printer</v-icon>
              Сформувати
            </v-btn>
            <template v-if="month_select">
              <MonthComponent
                  v-model="date_start"
                  @onMonthChange="monthChange"
                  :depressed="false"
                  :class_="'grey lighten-3 mr-2'"
                  :label="'Місяць звіту'"
              />
            </template>
            <template v-if="show_dates && !month_select">
                <MonthComponent
                    v-model="date_start"
                    @onMonthChange="monthChange(false)"
                    :depressed="false"
                    :class_="'grey lighten-3 mr-2'"
                    :label="'Період з'"
                    :day-type="show_full_dates"
                />
                <MonthComponent
                    v-model="date_end"
                    @onMonthChange="monthChangeEnd"
                    :depressed="false"
                    :class_="$vuetify.breakpoint.sm ? 'grey lighten-3' : 'grey lighten-3 mr-2'"
                    :label="'по'"
                    :day-type="show_full_dates"
                />
            </template>
          </div>
          <div class="d-flex mt-2">
            <v-tooltip bottom v-if="show_expand">
              <template v-slot:activator="{ on, attrs }">
                <v-btn width="40" class="mr-2" @click="open_all = true" v-bind="attrs" v-on="on" ref="expand_all_button">
                  <v-icon>mdi-arrow-expand-down</v-icon>
                </v-btn>
              </template>
              <span>Розгорнути усі групи</span>
            </v-tooltip>
            <v-tooltip bottom v-if="show_expand">
              <template v-slot:activator="{ on, attrs }">
                <v-btn width="40" class="mr-2" @click="open_all = false" v-bind="attrs" v-on="on" red="collapse_all_button">
                  <v-icon>mdi-arrow-expand-up</v-icon>
                </v-btn>
              </template>
              <span>Згорнути усі групи</span>
            </v-tooltip>
            <v-text-field v-if="show_scale" type="number" v-model="scale" solo class="cs-date mr-2" label="Масштаб" hide-details style="flex: 0 0 80px"/>
            <v-btn
                class="grey lighten-3 mr-2"
                width="54"
                outlined
                @click.stop="openSettings"
                v-if="show_settings"
            >
              <v-icon color="teal">mdi-filter-menu-outline</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3 mr-2"
                width="54"
                outlined
                @click.stop="print"
            >
              <v-icon color="primary">mdi-printer</v-icon>
            </v-btn>
            <v-btn
                v-if="send_by_email_button"
                @click.stop="showStartSendingQuestion"
                :loading="email_status"
                class="grey lighten-3 mr-2"
                width="54"
                style="flex: 1"
                outlined
            >
              <v-icon color="secondary">mdi-email</v-icon>
            </v-btn>
            <v-btn
                v-if="show_send_email_by_flat && !flat.person_id"
                @click.stop="showStartEmailSendingQuestion"
                :loading="email_by_flat_status"
                class="grey lighten-3 mr-2"
                width="54"
                style="flex: 1"
                outlined
            >
              <v-icon color="error">mdi-email-multiple</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3"
                width="54"
                outlined
                @click.stop="generate_xlsx = true"
                v-if="xlsx_export"
                :loading="xlsx_loading"
            >
              <v-icon color="success">mdi-file-excel-outline</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3 ml-2"
                width="54"
                outlined
                @click.stop="closeModal"
                v-if="component_name"
            >
              <v-icon color="grey darken-2">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-else-if="$vuetify.breakpoint.xs">
        <div class="py-2" style="width: 100%;">
          <div class="d-flex mb-2">
            <v-btn
                class="grey lighten-2 mr-2"
                style="flex: 1"
                @click.stop="generate_report"
                :disabled="!date_start || !date_end"
                :loading="report_loading"
            >
              <v-icon left class="mr-2" color="primary">mdi-printer</v-icon>
              Сформувати
            </v-btn>
            <v-tooltip bottom v-if="show_expand">
              <template v-slot:activator="{ on, attrs }">
                <v-btn width="40" style="flex: 0 0 40px" class="mr-2" @click="open_all = true" v-bind="attrs" v-on="on" ref="expand_all_button">
                  <v-icon>mdi-arrow-expand-down</v-icon>
                </v-btn>
              </template>
              <span>Розгорнути усі групи</span>
            </v-tooltip>
            <v-tooltip bottom v-if="show_expand">
              <template v-slot:activator="{ on, attrs }">
                <v-btn width="40" style="flex: 0 0 40px" @click="open_all = false" v-bind="attrs" v-on="on" ref="collapse_all_button">
                  <v-icon>mdi-arrow-expand-up</v-icon>
                </v-btn>
              </template>
              <span>Згорнути усі групи</span>
            </v-tooltip>
          </div>
          <template v-if="month_select">
            <div class="mb-2">
              <MonthComponent
                  v-model="date_start"
                  @onMonthChange="monthChange"
                  :depressed="false"
                  :block="true"
                  :class_="'grey lighten-3 mr-2'"
                  :label="'Місяць звіту'"
              />
            </div>
          </template>
          <template v-if="show_dates && !month_select">
            <div class="mb-2">
              <MonthComponent
                  v-model="date_start"
                  @onMonthChange="monthChange(false)"
                  :depressed="false"
                  :class_="'grey lighten-3 mr-2'"
                  :label="'Період з'"
                  :block="true"
                  :day-type="show_full_dates"
              />
            </div>
            <div>
              <MonthComponent
                  v-model="date_end"
                  @onMonthChange="monthChangeEnd"
                  :depressed="false"
                  :class_="'grey lighten-3 mr-2'"
                  :label="'по'"
                  :block="true"
                  :day-type="show_full_dates"
              />
            </div>
          </template>
          <div class="d-flex mt-2">
            <v-text-field v-if="show_scale" type="number" v-model="scale" solo class="cs-date mr-2" label="Масштаб" hide-details style="flex: 0 0 80px"/>
            <v-btn
                class="grey lighten-3 mr-2"
                width="54"
                style="flex: 1"
                outlined
                @click.stop="openSettings"
                v-if="show_settings"
            >
              <v-icon color="teal">mdi-filter-menu-outline</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3 mr-2"
                width="54"
                style="flex: 1"
                outlined
                @click.stop="print"
            >
              <v-icon color="primary">mdi-printer</v-icon>
            </v-btn>
            <v-btn
                v-if="send_by_email_button"
                @click.stop="showStartSendingQuestion"
                :loading="email_status"
                class="grey lighten-3 mr-2"
                width="54"
                style="flex: 1"
                outlined
            >
              <v-icon color="secondary">mdi-email</v-icon>
            </v-btn>
            <v-btn
                v-if="show_send_email_by_flat && !flat.person_id"
                @click.stop="showStartEmailSendingQuestion"
                :loading="email_by_flat_status"
                class="grey lighten-3 mr-2"
                width="54"
                style="flex: 1"
                outlined
            >
              <v-icon color="error">mdi-email-multiple</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3"
                width="54"
                outlined
                style="flex: 1"
                v-if="xlsx_export"
                :loading="xlsx_loading"
                @click.stop="generate_xlsx = true"
            >
              <v-icon color="success">mdi-file-excel-outline</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3 ml-2"
                width="54"
                outlined
                @click.stop="closeModal"
                v-if="component_name"
            >
              <v-icon color="grey darken-2">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-else>
        <v-btn
            class="grey lighten-2 mr-2"
            @click.stop="generate_report"
            :disabled="!date_start || !date_end"
            :loading="report_loading"
        >
          <v-icon left class="mr-2" color="primary">mdi-printer</v-icon>
          Сформувати
        </v-btn>
        <template v-if="month_select">
          <MonthComponent
              v-model="date_start"
              @onMonthChange="monthChange"
              :depressed="false"
              :class_="'grey lighten-3 mr-2'"
              :label="'Місяць звіту'"
          />
        </template>
        <template v-if="show_dates && !month_select">
          <template v-if="date_as_input">
            <date_input v-model="date_start"
                        label="Період з:" :button_style="true" :class="'mr-2'" outer-style="flex: 0 0 250px;"/>
            <date_input v-model="date_end"
                        label="по:" :button_style="true" :class="'mr-2'" outer-style="flex: 0 0 190px;"/>
          </template>
          <template v-else>
            <MonthComponent
                v-model="date_start"
                @onMonthChange="monthChange(false)"
                :depressed="false"
                :class_="'grey lighten-3 mr-2'"
                :label="'Період з'"
                :day-type="show_full_dates"
            />
            <MonthComponent
                v-model="date_end"
                @onMonthChange="monthChangeEnd"
                :depressed="false"
                :class_="'grey lighten-3 mr-2'"
                :label="'по'"
                :day-type="show_full_dates"
            />
          </template>

        </template>
        <template v-if="show_service">
        </template>
        <v-tooltip bottom v-if="show_expand">
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40" class="mr-2" @click="open_all = true" v-bind="attrs" v-on="on" ref="expand_all_button">
              <v-icon>mdi-arrow-expand-down</v-icon>
            </v-btn>
          </template>
          <span>Розгорнути усі групи</span>
        </v-tooltip>
        <v-tooltip bottom v-if="show_expand">
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40" class="mr-2" @click="open_all = false" v-bind="attrs" v-on="on" ref="collapse_all_button">
              <v-icon>mdi-arrow-expand-up</v-icon>
            </v-btn>
          </template>
          <span>Згорнути усі групи</span>
        </v-tooltip>
        <v-text-field v-if="show_scale" type="number" v-model="scale" solo class="cs-date" label="Масштаб" hide-details style="flex: 0 0 80px"/>
        <v-spacer/>
        <v-btn
            class="grey lighten-3 mr-2"
            icon
            @click.stop="openSettings"
            v-if="show_settings"
            height="42"
            width="44"
        >
          <v-icon color="teal">mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-btn
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="print"
            height="42"
            width="44"
        >
          <v-icon color="primary">mdi-printer</v-icon>
        </v-btn>
        <v-btn
            v-if="send_by_email_button"
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="showStartSendingQuestion"
            :loading="email_status"
            height="42"
            width="44"
        >
          <v-icon color="secondary">mdi-email</v-icon>
        </v-btn>
        <v-btn
            v-if="show_send_email_by_flat && !flat.person_id"
            @click.stop="showStartEmailSendingQuestion"
            :loading="email_by_flat_status"
            class="grey lighten-3 mr-2"
            height="42"
            width="44"
            outlined
            icon
        >
          <v-icon color="error">mdi-email-multiple</v-icon>
        </v-btn>
        <v-btn
            class="grey lighten-3"
            icon
            outlined
            @click.stop="generate_xlsx = true"
            v-if="xlsx_export"
            :loading="xlsx_loading"
            height="42"
            width="44"
        >
          <v-icon color="success">mdi-file-excel-outline</v-icon>
        </v-btn>
        <v-btn
            class="grey lighten-3 ml-2"
            icon
            outlined
            @click.stop="closeModal"
            v-if="component_name"
        >
          <v-icon color="grey darken-2">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <div class="wrapper-page" ref="document">
      <template v-if="page_wrapper">
        <div class="page printable page-for-print" :class="orientation" id="print" :style="`zoom: ${scale/100}`">
          <component
              :is="getComponent_"
              :organization="organization"
              :print_data="print_data"
              :global_settings="settings"
              :scale="scale"
              :date_start="date_start"
              :date_end="date_end"
              :generate="generate"
              :generate_xlsx="generate_xlsx"
              :generate_email="generate_email"
              :generate_send_by_one_email="generate_send_by_one_email"
              :auto_generate="auto_generate"
              :payload_int="payload_int"
              :payload_add_int_1="payload_add_int_1"
              :payload_add_int_2="payload_add_int_2"
              :payload_add_int_3="payload_add_int_3"
              :payload_add_str_1="payload_add_str_1"
              :payload_add_str_2="payload_add_str_2"
              :payload_add_str_3="payload_add_str_3"
              :open_all="open_all"
              :expand_button="expand_button"
              :collapse_button="collapse_button"
              :clear="clear"
              :flat="flat"
              :service_id="service_id"
              :item="item"
              :email_address="email"
              @closeSettings="closeSettings"
              @report_loading_status="setReportLoading"
              @xlsx_loading_status="setXlsxLoading"
              @email_loading_status="setSendingEmailStatus"
              @email_by_one_loading_status="setSendingEmailByOneStatus"
              @generate_report="generate_report"
              @generate_xlsx="generate_xlsx_report"
              @generate_email="generate_email_send"
              @generate_send_by_one_email="generate_send_by_one_email_send"
              @afterClear="afterClear"
              @clearOpenAll="open_all = undefined"
          />
        </div>
      </template>
      <template v-else>
        <div class="doc">
          <component
              :is="getComponent_"
              :organization="organization"
              :print_data="print_data"
              :global_settings="settings"
              :scale="scale"
              :date_start="date_start"
              :date_end="date_end"
              :generate="generate"
              :generate_xlsx="generate_xlsx"
              :generate_email="generate_email"
              :generate_send_by_one_email="generate_send_by_one_email"
              :open_all="open_all"
              :expand_button="expand_button"
              :collapse_button="collapse_button"
              :clear="clear"
              :flat="flat"
              :service_id="service_id"
              :auto_generate="auto_generate"
              :payload_int="payload_int"
              :payload_add_int_1="payload_add_int_1"
              :payload_add_int_2="payload_add_int_2"
              :payload_add_int_3="payload_add_int_3"
              :payload_add_str_1="payload_add_str_1"
              :payload_add_str_2="payload_add_str_2"
              :payload_add_str_3="payload_add_str_3"
              :item="item"
              :email_address="email"
              @closeSettings="closeSettings"
              @report_loading_status="setReportLoading"
              @xlsx_loading_status="setXlsxLoading"
              @email_loading_status="setSendingEmailStatus"
              @email_by_one_loading_status="setSendingEmailByOneStatus"
              @generate_report="generate_report"
              @generate_xlsx="generate_xlsx_report"
              @generate_email="generate_email_send"
              @generate_send_by_one_email="generate_send_by_one_email_send"
              @afterClear="afterClear"
              @clearOpenAll="open_all = undefined"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {GET_ORGANIZATION_PRINT_DATA} from "@/store/actions/organization";
import {beginOfMonth, endOfMonth, getCurrentDate} from "@/utils/icons";
import {QUESTION_SHOW} from "@/store/actions/question";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "PrintReport",
  props: {
    flat: {
      type: Object,
      default() {
        return {}
      }
    },
    component_name: {
      type: String,
      default: ''
    },
    payload_int: {
      type: Number,
      default: null
    },
    payload_add_int_1: {
      type: Number,
      default: null
    },
    payload_add_int_2: {
      type: Number,
      default: null
    },
    payload_add_int_3: {
      type: Number,
      default: null
    },
    payload_add_str_1: {
      type: String,
      default: ""
    },
    payload_add_str_2: {
      type: String,
      default: ""
    },
    payload_add_str_3: {
      type: String,
      default: ""
    },
    payload_date_start: {
      type: String,
      default: ''
    },
    payload_date_end: {
      type: String,
      default: ''
    },
    auto_generate: {
      type: Boolean,
      default: false
    },
    send_by_email_button: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    // general_report,
    // receipt_simply,
    // pays_by_date_by_flat,
    // contracts_stats,
    MonthComponent: () => import("@/components/MonthComponent"),
    date_input: () => import("@/components/accounting/input/document_date_input.vue")
  },
  computed: {
    ...mapGetters({
      modalAnswer: 'question_answer',
      organization: 'getCurrentOrganization',
      current_month: 'getCurrentMonth',
      current_month_legacy: 'getCurrentMonthLegacy',
      services: 'getServicesSelectWithoutAll',
      legacy: 'isLegacy'
    }),
    getComponent_() {
      const report_name = this.component_name ? this.component_name : (this.$route.params['report_name'] || '')

      if (report_name === "general_report") {
        return () => import("@/components/reports/general_report")
      }

      if (report_name === "general_report_legacy") {
        return () => import("@/components/reports/general_report_legacy")
      }

      if (report_name === "general_report_by_flat") {
        return () => import("@/components/reports/general_report_by_flat_new")
      }

      if (report_name === "new_general_report_with_grouping") {
        return () => import("@/components/reports/new_general_report_with_grouping.vue")
      }

      if (report_name === "universal_report_by_flat") {
        return () => import("@/components/reports/universal_report_by_flat.vue")
      }

      if (report_name === "dispatcher_calls_history") {
        return () => import("@/components/reports/dispatcher_calls_history.vue")
      }


      if (report_name === "dispatcher_calls_stats") {
        return () => import("@/components/reports/dispatcher_calls_stats.vue")
      }

      if (report_name === "universal_spreed_sheet_report_by_flat") {
        return () => import("@/components/reports/universal_spreed_sheet_report_by_flat.vue")
      }

      if (report_name === "universal_pretense_report_by_flat") {
        return () => import("@/components/reports/pretense_report_by_flat.vue")
      }

      if (report_name === "universal_court_case_report_by_flat") {
        return () => import("@/components/reports/court_case_report_by_flat.vue")
      }

      if (report_name === "universal_general_report_by_grouping") {
        return () => import("@/components/reports/universal_general_report_by_grouping.vue")
      }

      if (report_name === "general_report_by_flat_legacy") {
        return () => import("@/components/reports/general_report_by_flat_legacy")
      }

      if (report_name === "general_report_by_flat_by_service") {
        return () => import("@/components/reports/general_report_by_flat_by_service")
      }

      if (report_name === "general_report_by_flat_by_service_legacy") {
        return () => import("@/components/reports/general_report_by_flat_by_service_legacy")
      }

      if (report_name === "indicator_check_contract_and_service_report_by_flat") {
        return () => import("@/components/reports/indicator_check_contract_and_service_report_by_flat")
      }

      if (report_name === "indicator_report_by_flat") {
        return () => import("@/components/reports/indicator_report_by_flat")
      }

      if (report_name === "indicator_report_by_flat_legacy") {
        return () => import("@/components/reports/indicator_report_by_flat_legacy")
      }

      if (report_name === "pays_by_service_bank_and_date_general_report") {
        return () => import("@/components/reports/pays_by_service_bank_and_date_general_report")
      }

      if (report_name === "pays_by_service_and_date_report_by_flat") {
        return () => import("@/components/reports/pays_by_service_and_days_report_by_flat")
      }

      if (report_name === "salary_main_report") {
        return () => import("@/components/accounting/reports/SalaryMainReport")
      }

      if (report_name === "accounting_main_report") {
        return () => import("@/components/accounting/reports/AccountingMainReport")
      }

      if (report_name === "accounting_main_report_by_code") {
        return () => import("@/components/accounting/reports/AccountingMainReportByCode")
      }

      if (report_name === 'accounting_card_report_by_code') {
        return () => import("@/components/accounting/reports/AccountingCardReportByCode")
      }

      if (report_name === "salary_contribution_and_taxes") {
        return () => import("@/components/accounting/reports/SalaryContributionAndTaxes")
      }

      if (report_name === "salary_total_contribution_and_taxes") {
        return () => import("@/components/accounting/reports/SalaryTotalContributionAndTaxes")
      }

      if (report_name === "salary_account_list") {
        return () => import("@/components/accounting/reports/SalaryAccountList")
      }

      if (report_name === "pays_by_date_report_by_flat") {
        return () => import("@/components/reports/pays_by_date_report_by_flat")
      }

      if (report_name === "privilege_report_blank_2") {
        return () => import("@/components/reports/privilege_report_blank_2_new")
      }

      if (report_name === "contract_stats") {
        return () => import("@/components/reports/contract_stats_new")
      }

      if (report_name === "archive_report_by_flat") {
        return () => import("@/components/reports/archive_report_by_flat_new")
      }

      if (report_name === "pays_by_date_by_flat") {
        return () => import("@/components/reports/pays_by_date_by_flat")
      }

      if (report_name === "reading_statement") {
        return () => import("@/components/reports/reading_statement_new")
      }

      if (report_name === "recalculation_report_by_flat") {
        return () => import("@/components/reports/recalculation_report_by_flat")
      }

      if (report_name === "removal_report_by_flat") {
        return () => import("@/components/reports/removal_report_by_flat")
      }

      if (report_name === "flat_by_norm_and_counter_report") {
        return () => import("@/components/reports/flat_by_norm_and_counter")
      }

      if (report_name === "debt_report_by_flat") {
        return () => import("@/components/reports/debt_report_by_flat_new")
      }

      if (report_name === "debt_report_by_pages") {
        return () => import("@/components/reports/debt_by_pages.vue")
      }

      if (report_name === "restructuring_agreement_status") {
        return () => import("@/components/reports/restructuring_agreement_status")
      }

      if (report_name === "debt_report_by_flat_legacy") {
        return () => import("@/components/reports/debt_report_by_flat_legacy")
      }

      if (report_name === "debt_group_report_by_flat") {
        return () => import("@/components/reports/debt_group_report_by_flat")
      }

      if (report_name === "debt_group_report_by_flat_legacy") {
        return () => import("@/components/reports/debt_group_report_by_flat_legacy")
      }

      if (report_name === "reading_balance") {
        return () => import("@/components/reports/reading_balance")
      }

      if (report_name === "receipt_simply") {
        return () => import("@/components/reports/receipt_simply")
      }

      if (report_name === "receipt_detail") {
        return () => import("@/components/reports/receipt_detail")
      }

      if (report_name === "receipt_detail_with") {
        return () => import("@/components/reports/receipt_detail_with")
      }

      if (report_name === "warning_standart") {
        return () => import("@/components/reports/warning_standart")
      }

      if (report_name === "person_hash_list_by_flat") {
        return () => import("@/components/reports/person_hash_list_by_flat")
      }

      if (report_name === "provision_of_services") {
          return () => import("@/components/reports/provision_of_services")
      }

      if (report_name === "counter_count") {
          return () => import("@/components/reports/counter_count")
      }

      if (report_name === "counter_verification_end") {
          return () => import("@/components/reports/counter_verification_end")
      }

      if (report_name === "statement_building_list_info") {
          return () => import("@/components/reports/statement_building_list_info")
      }

      if (report_name === "restructuring_agreement") {
        return () => import("@/components/reports/restructuring_agreement")
      }

      return null
    },
    report_name() {
      return this.component_name ? this.component_name : (this.$route.params['report_name'] || '')
    },
    appBarHeight() {
      if (this.$vuetify.breakpoint.xs) {
        return '200px'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '100px'
      }

      return '48px'

    }
  },
  data() {
    return {
      emailRules: [
        v => !!v || 'Це поле э обов’язковим',
        v => /.+@.+/.test(v) || 'Неправильний email',
      ],
      formValid: false,
      open_period: false,
      orientation: 'page-a4',
      page_wrapper: true,
      scale: 100,
      settings: false,
      print_data: {},
      date_start: this.current_month,
      date_end: null,
      month_select: false,
      generate: false,
      generate_xlsx: false,
      generate_email: false,
      generate_send_by_one_email: false,
      report_data_loaded: false,
      xlsx_loading: false,
      email_status: false,
      email_by_flat_status: false,
      clear: false,
      open_all: undefined,
      show_expand: true,
      show_scale: true,
      show_settings: true,
      page_splitter: false,
      show_dates: true,
      date_as_input: false,
      show_full_dates: false,
      show_service: false,
      service_id: null,
      xlsx_export: false,
      expand_button: null,
      collapse_button: null,
      report_loading: false,
      email_watcher: null,
      show_email_dialog: false,
      show_send_email_by_flat: false,
      email: '',
    }
  },
  methods: {
    afterClear() {
      this.clear = false
    },
    monthChange(change_date_end=true) {
      if (this.date_start) {
        const new_date_end = this.getEndOfMonth(this.date_start)
        if (this.date_end !== new_date_end) {
          this.clear = true
          this.report_data_loaded = false
        }
        if (change_date_end) {
          this.date_end = new_date_end
        }
      }
    },
    monthChangeEnd() {
      if (this.date_end) {
        this.date_end = this.getEndOfMonth(this.date_end)
      }
    },
    closeModal() {
      this.date_start = this.legacy ? this.$store.getters.getCurrentMonthLegacy : this.$store.getters.getCurrentMonth
      if (this.date_start) {
        this.getEndOfMonth(this.date_start)
      }
      this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
          .then(
              data => {
                this.print_data = data
              }
          )
          .finally(() => this.$emit('closeModal', true))
    },
    openSettings() {
      this.settings = !this.settings
    },
    getComponent() {
      const report_name = this.report_name

      if (report_name === "general_report") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = true
        this.month_select = false
        this.show_expand = false
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        this.show_settings = true
      }

      if (report_name === "general_report_legacy") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = true
        this.month_select = false
        this.show_expand = false
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        this.show_settings = true
      }

      if (report_name === "general_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "new_general_report_with_grouping") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "universal_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "dispatcher_calls_history") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "dispatcher_calls_stats") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "universal_spreed_sheet_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "universal_pretense_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "universal_court_case_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "universal_general_report_by_grouping") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "salary_main_report") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "accounting_main_report") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "accounting_main_report_by_code") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "accounting_card_report_by_code") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "salary_contribution_and_taxes") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = true
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "general_report_by_flat_legacy") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "general_report_by_flat_by_service") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "general_report_by_flat_by_service_legacy") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "indicator_check_contract_and_service_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "indicator_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "indicator_report_by_flat_legacy") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "pays_by_service_and_date_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 120
        this.show_settings = true
      }

      if (report_name === "pays_by_service_bank_and_date_general_report") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 120
        this.show_settings = true
      }

      if (report_name === "pays_by_date_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 120
        this.show_settings = true
      }

      if (report_name === "privilege_report_blank_2") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = false
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "contract_stats") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 116
        this.show_settings = true
      }

      if (report_name === "salary_total_contribution_and_taxes") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = false
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = false
        if (this.scale === 100) this.scale = 110
        this.show_settings = true
      }

      if (report_name === "archive_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = false
        this.show_settings = false
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
      }

      if (report_name === "pays_by_date_by_flat") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 130
        this.show_settings = true
      }

      if (report_name === "reading_statement") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = false
        this.page_splitter = true
        this.show_service = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "recalculation_report_by_flat") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = false
        this.page_splitter = true
        this.show_service = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "person_hash_list_by_flat") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = false
        this.page_splitter = true
        this.show_service = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "removal_report_by_flat") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = false
        this.page_splitter = true
        this.show_service = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "flat_by_norm_and_counter_report") {
        this.orientation = 'page-a4-auto'
        this.page_wrapper = false
        this.month_select = false
        this.show_expand = true
        this.show_dates = true
        this.show_scale = false
        this.page_splitter = true
        this.show_service = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "restructuring_agreement_status") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "debt_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "debt_report_by_flat_legacy") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "debt_group_report_by_flat") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "debt_group_report_by_flat_legacy") {
        this.orientation = 'page-a4-landscape-auto'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "reading_balance") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_expand = true
        this.show_dates = true
        this.show_scale = true
        this.page_splitter = true
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        if (this.scale === 100) this.scale = 100
        this.show_settings = true
      }

      if (report_name === "provision_of_services") {
          this.orientation = 'page-a4'
          this.page_wrapper = false
          this.month_select = true
          this.show_expand = true
          this.show_dates = true
          this.show_scale = true
          this.page_splitter = true
          this.xlsx_export = true
          this.show_full_dates = false
          this.date_as_input = false
          if (this.scale === 100) this.scale = 100
          this.show_settings = true
      }

      if (report_name === "receipt_simply") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_dates = true
        this.show_expand = false
        this.show_scale = false
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        this.show_settings = true
        this.show_send_email_by_flat = true
      }

      if (report_name === "debt_report_by_pages") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_dates = true
        this.show_expand = false
        this.show_scale = false
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        this.show_settings = true
      }

      if (report_name === "receipt_detail") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_dates = true
        this.show_expand = false
        this.show_scale = false
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        this.show_settings = true
        this.show_send_email_by_flat = true
      }

      if (report_name === "receipt_detail_with") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_dates = true
        this.show_expand = false
        this.show_scale = false
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        this.show_settings = true
        this.show_send_email_by_flat = true
      }

      if (report_name === "salary_account_list") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_dates = true
        this.show_expand = false
        this.show_scale = false
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = true
        this.date_as_input = false
        this.show_settings = true
      }

      if (report_name === "warning_standart") {
        this.orientation = 'page-a4'
        this.page_wrapper = false
        this.month_select = true
        this.show_dates = true
        this.show_expand = false
        this.show_scale = false
        this.page_splitter = false
        this.xlsx_export = true
        this.show_full_dates = false
        this.date_as_input = false
        this.show_settings = true
      }

      if (report_name === "counter_count") {
          this.orientation = 'page-a4'
          this.page_wrapper = false
          this.month_select = true
          this.show_expand = true
          this.show_dates = true
          this.show_scale = true
          this.page_splitter = true
          this.xlsx_export = true
          this.show_full_dates = false
          this.date_as_input = false
          if (this.scale === 100) this.scale = 100
          this.show_settings = true
      }

        if (report_name === "statement_building_list_info") {
            this.orientation = 'page-a4'
            this.page_wrapper = false
            this.month_select = true
            this.show_expand = true
            this.show_dates = true
            this.show_scale = true
            this.page_splitter = true
            this.xlsx_export = true
            this.show_full_dates = false
            this.date_as_input = false
            if (this.scale === 100) this.scale = 100
            this.show_settings = true
        }

        if (report_name === "counter_verification_end") {
            this.orientation = 'page-a4'
            this.page_wrapper = false
            this.month_select = false
            this.show_expand = true
            this.show_dates = false
            this.show_scale = true
            this.page_splitter = true
            this.xlsx_export = true
            this.show_full_dates = false
            this.date_as_input = false
            if (this.scale === 100) this.scale = 100
            this.show_settings = true
        }

        if (report_name === "restructuring_agreement") {
          this.orientation = 'page-a4'
          this.page_wrapper = false
          this.month_select = false
          this.show_expand = false
          this.show_dates = false
          this.show_scale = false
          this.page_splitter = false
          this.xlsx_export = true
          this.show_full_dates = false
          this.date_as_input = false
          if (this.scale === 100) this.scale = 100
          this.show_settings = false
        }

    },
    closeSettings() {
      this.settings = false
    },
    print() {
      window.print()
    },
    showStartSendingQuestion() {
      const payload = {
        text: `Підтвердіть відправлення по email`,
        accept_button: true,
        id: 'start_sending_report_by_email_print_report'
      }
      this.show_email_dialog = false
      this.generate_email = false
      this.email = this.flat.email || ''
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    showStartEmailSendingQuestion() {
      const payload = {
        text: `Розпочати розсилку рахунків на email абонентів`,
        accept_button: true,
        id: 'start_sending_receipt_by_email_print_report'
      }
      this.show_email_dialog = false
      this.generate_send_by_one_email = false
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    email_dialog_close() {
      this.email = ''
      this.show_email_dialog = false
    },
    email_dialog_send() {
      if (!this.email) {
        this.$store.commit(ALERT_SHOW, {text: 'Вкажіть email', color: 'error'})
        return
      }
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Email порожній або не відповідає структурі email адреси',
          color: 'error lighten-1'
        })
        return
      }
      this.generate_email = !this.generate_email
    },
    getEndOfMonth(date) {
      if (date) {
        const splited = date.split('-')
        const new_date = new Date(splited[0], +splited[1], 0)

        const year = new_date.getUTCFullYear()
        const month = new_date.getUTCMonth() + 1 < 10 ? '0' + (new_date.getUTCMonth() + 1) : new_date.getUTCMonth() + 1
        const day = new_date.getUTCDate() + 1 < 10 ? '0' + (new_date.getUTCDate() + 1) : new_date.getUTCDate() + 1
        return `${year}-${month}-${day}`
      }
    },
    setReportLoading(payload) {
      this.report_loading = payload
    },
    setXlsxLoading(payload) {
      this.xlsx_loading = payload
    },
    setSendingEmailStatus(payload) {
      this.email_status = payload
      this.show_email_dialog = false
    },
    setSendingEmailByOneStatus(payload) {
      this.email_by_flat_status = payload
    },
    generate_report(payload) {
      this.generate = !this.generate
      if (payload) {
        this.report_data_loaded = true
      }
    },
    generate_xlsx_report() {
      this.generate_xlsx = !this.generate_xlsx
    },
    generate_email_send() {
      this.generate_email = !this.generate_email
    },
    generate_send_by_one_email_send() {
      this.generate_send_by_one_email = !this.generate_send_by_one_email
    },
    watch_modal_answer() {
      this.email_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === 'start_sending_report_by_email_print_report') {
                if (payload.answer) {
                  this.show_email_dialog = true
                }
              }
              if (payload.id === 'start_sending_receipt_by_email_print_report') {
                if (payload.answer) {
                  this.generate_send_by_one_email = true
                }
              }
            }
          }
      )
    },
},
  created() {
    this.watch_modal_answer()
    if (this.component_name.startsWith('accounting_') || this.component_name.startsWith('salary_')) {
      if (!this.auto_generate) {
        this.date_start = beginOfMonth(getCurrentDate())
        if (this.date_start) {
          this.date_end = endOfMonth(this.date_start)
        }
      } else {
        if (this.payload_date_start) {
          this.date_start = this.payload_date_start
          this.date_end = this.payload_date_end
        } else {
          this.date_start = beginOfMonth(getCurrentDate())
          if (this.date_start) {
            this.date_end = endOfMonth(this.date_start)
          }
        }
      }
    } else {
      this.date_start = this.legacy ? this.$store.getters.getCurrentMonthLegacy : this.$store.getters.getCurrentMonth
      if (this.date_start) {
        this.date_end = this.getEndOfMonth(this.date_start)
      }
    }

    this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
        .then(
            data => this.print_data = data
        )
  },
  mounted() {
    this.$nextTick(() => {
      this.collapse_button = this.$refs.collapse_all_button
      this.expand_button = this.$refs.expand_all_button
    })
  },
  beforeDestroy() {
    if (this.email_watcher) {
      this.email_watcher()
    }
  },
  watch: {
    current_month: {
      immediate: true,
      handler(payload) {
        this.date_start = payload
        if (payload && !this.date_end) {
          this.date_end = this.getEndOfMonth(payload)
        }
      }
    },
    current_month_legacy: {
      immediate: true,
      handler(payload) {
        this.date_start = payload
        if (payload && !this.date_end) {
          this.date_end = this.getEndOfMonth(payload)
        }
      }
    },
    report_name: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.getComponent()

          if (this.component_name.startsWith('accounting_') || this.component_name.startsWith('salary_')) {
            if (!this.auto_generate) {
              this.date_start = beginOfMonth(getCurrentDate())
              if (this.date_start) {
                this.date_end = endOfMonth(this.date_start)
              }
            } else {
              if (this.payload_date_start) {
                this.date_start = this.payload_date_start
                this.date_end = this.payload_date_end
              } else {
                this.date_start = beginOfMonth(getCurrentDate())
                if (this.date_start) {
                  this.date_end = endOfMonth(this.date_start)
                }
              }
            }
          } else {
            this.date_start = this.legacy ? this.$store.getters.getCurrentMonthLegacy : this.$store.getters.getCurrentMonth
            if (this.date_start) {
              this.getEndOfMonth(this.date_start)
            }
          }
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .cs-date {
    flex: 0 0 180px;

    &:deep(.v-input__control) {
      min-height: 36px !important;
    }
  }

  .print-page-component {
    .page {
      padding: 4mm 4mm 4mm 5mm !important;
    }
  }
  .notify {
    width: 320px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 56px;
    left: 15px;
    background: #ffffff;
    padding: 12px;
    color: grey;
    font-size: 0.91rem;
    text-align: center;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 5px;
    border-right: 4px solid #4caf50;
  }
</style>
